import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import FontAwesomeIcon from '@/utilities/fontawesome.ts'

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(VueAxios, axios)
    .use(router)
    .mount('#app')