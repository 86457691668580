<template>
    <div class="container">
        <div class="gallery-container">
            <div class="heading">
                <div class="content">
                    <div class="return">
                        <a href="/">
                            <font-awesome-icon icon="fa-solid fa-angle-left" />
                        </a>
                    </div>
                    <div class="button circle-button">{{ number }}</div>
                    <div class="title">{{ title }}</div>
                </div>
            </div>
            <a href="https://coronetled.crservrs.com/contact-us/" class="button square-button contact-button">Contact Sales Team</a>
            <ul class="image-gallery">
                <li class="cssbox" v-for="(item, index) in gallery" :key="item">
                    <a :id="`image${index}`" :href="`#image${index}`">
                        <img class="cssbox_thumb" :src="item.image">
                        <span class="cssbox_full"><img :src="item.image"></span>
                        <span class="plus">+</span>
                    </a>
                    <a class="name" :href="item.product_link.url" :target="item.product_link.target">
                        <font-awesome-icon icon="fa-solid fa-arrow-right" />
                        {{ item.image_title }}
                    </a>
                    <a class="cssbox_close" href="#void"></a>
                    <a class="cssbox_prev" :href="`#image${index-1}`">
                        <font-awesome-icon icon="fa-solid fa-angle-left" />
                    </a>
                    <a class="cssbox_next" :href="`#image${index+1}`">
                        <font-awesome-icon icon="fa-solid fa-angle-right" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>


export default {
    name: 'Gallery',
    components: {
    },
    data() {
        return {
            number: 1,
            title: null,
            slug: null,
            gallery: {}
        }
    },
    mounted () {
        let slug = this.$route.params.slug
        switch(slug) {
            case 'open-ceiling':
                this.number = 1
                break
            case 'recessed':
                this.number = 2
                break
            case 'pendant':
                this.number = 3
                break
            case 'surface-wall-mounted':
                this.number = 4
                break
        }
        this.slug = slug
        this.axios
        .get(`https://coronetled.crservrs.com/wp-json/bamboo/v1/get_product_images?slug=${this.slug}`)
        .then(
            response => {
                //console.log(response.data)
                //console.log(response.data[0].content[slug].content)
                this.title = response.data[0].content[slug].title
                this.gallery = response.data[0].content[slug].content
            }
        )
    },

    created () {
    },

    methods: {
    }
}
</script>

<style scoped>
.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
}

.heading {
    max-width: 1070px;
    margin: 0 auto;
    display: flex;
    align-content: center;
    align-items: left;
    justify-content: space-between;
    margin-bottom: 2rem;
    float: left;
    max-width: 90%;
}

.heading .title {
    font-family: 'Abitare Sans 700';
    font-size: 18px;
    text-align: left;
    margin-left: 10px;
}
.heading .content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}
.contact-button {
    margin-bottom: 10px;
    width: 180px;
    float: right;
}

.return a {
    font-family: 'Abitare Sans 700';
    font-size: 24px;
    color: #272452;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 25px;
}
@media screen and (max-width: 769px) {
    .heading {
        max-width: 100%;
    }
    .heading .title {
        font-size: 16px;
    }
}

ul {
    list-style: none;
}

/* Responsive image gallery rules begin*/

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.image-gallery > li {
    flex-basis: 350px; /*width: 350px;*/
    position: relative;
    cursor: pointer;
    height: 300px;
    max-height: 300px;
}

.image-gallery::after {
content: "";
flex-basis: 350px;
}

.image-gallery li img {
object-fit: cover;
width: 100%;
height: 100%;
vertical-align: middle;
border-radius: 5px;
}

.overlay {
position: absolute;
width: 100%;
height: 100%;
background: rgba(57, 57, 57, 0.502);
top: 0;
left: 0;
transform: scale(0);
transition: all 0.2s 0.1s ease-in-out;
color: #fff;
border-radius: 5px;
/* center overlay text */
display: flex;
align-items: center;
justify-content: center;
}

/* hover */
.image-gallery li:hover .overlay {
transform: scale(1);
}

.cssbox .plus,
.cssbox .name {
    color: #fff;
    text-decoration: none;
    position: absolute;
    font-family: 'Inter Extra Light BETA';
}

.cssbox .plus {
    font-size: 28px;
    top: 0;
    right: 10px;
}

.cssbox .name {
    font-size: 12px;
    bottom: 10px;
    left: 20px;
}
  
  span.cssbox_full {
    z-index: 999999;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    cursor: default;
    transition: opacity 0.5s linear;
  }
  
  span.cssbox_full img {
    position: fixed;
    background-color: white;
    margin: 0;
    padding: 0;
    max-height: 90%;
    max-width: 90%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 20px black;
  }
  
  a.cssbox_close,
  a.cssbox_prev,
  a.cssbox_next {
    z-index: 999999;
    position: fixed;
    text-decoration: none;
    visibility: hidden;
    color: white;
    font-size: 36px;
  }
  
  a.cssbox_close {
    top: 1%;
    right: 1%
  }
  
  a.cssbox_close::after {
    content: '\00d7';
  }
  
  a.cssbox_prev,
  a.cssbox_next {
    top: 50%;
    transform: translate(0%, -50%);
  }
  
  a.cssbox_prev {
    left: 5%;
  }
  
  a.cssbox_next {
    right: 5%;
  }
  
  a:target ~ a.cssbox_close,
  a:target ~ a.cssbox_prev,
  a:target ~ a.cssbox_next {
    visibility: visible;
  }
  
  a:target > img.cssbox_thumb + span.cssbox_full {
    visibility: visible;
    opacity: 1;
    pointer-events: initial;
  }
</style>
