<template>
  <div class="gallery">
    <Gallery />
  </div>
</template>

<script>
import Gallery from '@/components/Gallery'

export default {
  name: 'GalleryView',
  components: {
      Gallery,
  },
  data() {
        return {
        }
    },
}
</script>