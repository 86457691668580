
<template>
  <div class="body">
    <div class="container">
      <div id="bgcontainer" :class="'parent bgcontainer ' + (bghover && bghover)" @click="resetBackground">
        <input type="checkbox" class="input" id="input1" name="inputs">
        <input type="checkbox" class="input" id="input2" name="inputs">
        <input type="checkbox" class="input" id="input3" name="inputs">
        <input type="checkbox" class="input" id="input4" name="inputs">
        <label for="input1">
          <div id="b1" class="button circle-button" data-class="b1hover" data-check="input1" data-type="button" @mouseenter="checkToogle" @mouseleave="resetBackground">
            <span class="number">1</span>
            <span class="close">X</span>
          </div>
        </label>
        <label for="input2">
          <div id="b2" class="button circle-button" data-class="b2hover" data-check="input2" data-type="button" @mouseenter="checkToogle" @mouseleave="resetBackground">
            <span class="number">2</span>
            <span class="close">X</span>
          </div>
        </label>
        <label for="input3">
          <div id="b3" class="button circle-button" data-class="b3hover" data-check="input3" data-type="button" @mouseenter="checkToogle" @mouseleave="resetBackground">
            <span class="number">3</span>
            <span class="close">X</span>
          </div>
        </label>
        <label for="input4">
          <div id="b4" class="button circle-button" data-class="b4hover" data-check="input4" data-type="button" @mouseenter="checkToogle" @mouseleave="resetBackground">
            <span class="number">4</span>
            <span class="close">X</span>
          </div>
        </label>
        <div id="content1" class="content arrow-wrapper">
          <div class="arrow arrow--left">
            <span @click="slide" data-target="input4" data-class="b4hover">Prev</span>
          </div>
          <div class="block">
            <h1>Open Ceiling</h1>
            <a :href="'/gallery/open-ceiling'" class="cta">More</a>
          </div>
          <div class="arrow arrow--right">
            <span @click="slide" data-target="input2" data-class="b2hover">Next</span>
          </div>
        </div>
        <div id="content2" class="content arrow-wrapper">
          <div class="arrow arrow--left">
            <span @click="slide" data-target="input1" data-class="b1hover">Prev</span>
          </div>
          <div class="block">
            <h1>Recessed</h1>
            <a :href="'/gallery/recessed'" class="cta">More</a>
          </div>
          <div class="arrow arrow--right">
            <span @click="slide" data-target="input3" data-class="b3hover">Next</span>
          </div>
        </div>
        <div id="content3" class="content arrow-wrapper">
          <div class="arrow arrow--left">
            <span @click="slide" data-target="input2" data-class="b2hover">Prev</span>
          </div>
          <div class="block">
            <h1>Pendant</h1>
            <a :href="'/gallery/pendant'" class="cta">More</a>
          </div>
          <div class="arrow arrow--right">
            <span @click="slide" data-target="input4" data-class="b4hover">Next</span>
          </div>
        </div>
        <div id="content4" class="content arrow-wrapper">
          <div class="arrow arrow--left">
            <span @click="slide" data-target="input3" data-class="b3hover">Prev</span>
          </div>
          <div class="block">
            <h1>Surface, Wall Mounted</h1>
            <a :href="'/gallery/surface-wall-mounted'" class="cta">More</a>
          </div>
          <div class="arrow arrow--right">
            <span @click="slide" data-target="input1" data-class="b1hover">Next</span>
          </div>
        </div>
        <div class="mobile-overlay"></div> 
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 769px) {
  .home .body {
    background-image: url("@/assets/images/room.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.home .container {
  position: relative;
  height: 100% !important;
}
.mobile-overlay {
  position: absolute;
  background-color: transparent;
  width: 100vw;
  height: calc((100vh - 150px) / 6 * 5);
}
.bgcontainer {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow {
  display: none;
}

input {
  display: none;
}
p {
  margin: 0;
}
.button {
  animation: pulse 1.5s infinite;
  cursor: pointer;
}
h1 {
  font-size: 18px;
  margin: 5px;
}
.content {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  color: #ffffff;
  display: none !important;
}

input:checked ~ label .button {
  animation: none;
}
.cta {
  font-size: 12px;
  min-width: 60px;
  display: inline-block;
  background: #578FC4;
  color: white;
  text-align: center;
  padding: 6px;
  margin: 5px;
  text-decoration: none;
  transition: all .3s ease;
  text-transform: uppercase;
}

#input1 ~ #content1,
#input2 ~ #content2,
#input3 ~ #content3,
#input4 ~ #content4 {
  display: none;
}

@media screen and (min-width: 769px) {
  .mobile-overlay {
    display: none;
  }
  .bgcontainer {
    height: 60vw;
  }
  .bgcontainer.b1hover,
  .bgcontainer.b2hover,
  .bgcontainer.b3hover,
  .bgcontainer.b4hover {
    -webkit-transition: background-image 3s linear;
    -moz-transition: background-image 3s ease-in-out;
    -o-transition: background-image 3s ease-in-out;
    transition: background-image 3s ease-in-out;
    /* animation: fadein .5s ; */
  }
  .bgcontainer.b1hover {
    background-image:url("@/assets/images/room1.png");
  }
  .bgcontainer.b2hover {
    background-image:url("@/assets/images/room2.png");
  }
  .bgcontainer.b3hover {
    background-image:url("@/assets/images/room3.png");
  }
  .bgcontainer.b4hover {
    background-image:url("@/assets/images/room4.png");
  }
  #b1, #b2, #b3, #b4,
  #content1, #content2, #content3, #content4 {
    position: absolute;
  }

  #content1, #content2, #content3, #content4 {
    /* animation: fadein .5s ; */
    /* opacity: 1; */
  }
  #b1 {
    left: 12%;
    top: 30%;
  }
  #content1 {
    left: 14%;
    top: 34%;
  }
  #b2 {
    left: 32%;
    top: 40%;
  }
  #content2 {
    left: 34%;
    top: 44%;
  }
  #b3 {
    left: 64%;
    top: 25%;
  }
  #content3 {
    left: 66%;
    top: 28%;
  }
  #b4 {
    left: 70%;
    top: 48%;
  }
  #content4 {
    left: 72%;
    top: 52%;
  }
  #input1:hover ~ #content1,
  #input2:hover ~ #content2,
  #input3:hover ~ #content3,
  #input4:hover ~ #content4 {
    display: block !important;
  }
  #input1:checked ~ #content1,
  #input2:checked ~ #content2,
  #input3:checked ~ #content3,
  #input4:checked ~ #content4 {  
    display: block !important;
  }
  #input1:checked ~ label #b1 .number,
  #input1:not(:checked) ~ label #b1 .close {
    display: none !important;
  }
  #input2:checked ~ label #b2 .number,
  #input2:not(:checked) ~ label #b2 .close {
    display: none !important;
  }
  #input3:checked ~ label #b3 .number,
  #input3:not(:checked) ~ label #b3 .close {
    display: none !important;
  }
  #input4:checked ~ label #b4 .number,
  #input4:not(:checked) ~ label #b4 .close {
    display: none !important;
  }
}




@media screen and (max-width: 768px) {
  .bgcontainer {
    background-image:url("@/assets/images/room1.png");
    background-repeat: no-repeat;;
    height: calc(100vh - 155px);
    animation: all linear 0.5s;
    transition: all 0.5s;
  }
  .bgcontainer.b1hover {
    background-image:url("@/assets/images/room1.png");
  }
  .bgcontainer.b2hover {
    background-image:url("@/assets/images/room2.png");
    background-position-x: 35%;
  }
  .bgcontainer.b3hover {
    background-image:url("@/assets/images/room3.png");
    background-position-x: 55%;
  }
  .bgcontainer.b4hover {
    background-image:url("@/assets/images/room4.png");
    background-position-x: 95%;
  }
  .content {
    position: absolute;
    padding: 10px 0;
    bottom: 0;
    left: 0;
    height: calc((100vh - 150px) / 6);
    width: 100vw;
    display: block;
  }
  .close {
    display: none !important;
  }
  #b1, #b2, #b3, #b4 {
    position: absolute;
    left: 5%;
    top: 5%;
  }
  
  input:checked ~ .arrow-wrapper {
    align-items: center;
  }
  
  #input1:checked ~ #content1,
  #input2:checked ~ #content2,
  #input3:checked ~ #content3,
  #input4:checked ~ #content4 {  
    display: flex !important;
  }
  
  .block {
    min-width: calc(100vw - 90px);
    padding: 0 15px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

  .arrow {
    display: block;
    color: #fff;
    cursor: pointer;
    align-items: flex-start;
    text-transform: uppercase;
    position: relative;
    transition: transform 0.3s;
  }
  
  .arrow span {
    display: block;
    width: 0px;
    opacity: 0;
    padding: 0 15px;
  }
    
  /* .arrow:hover span {
    opacity: 1;
    transition: opacity 0.3s 0.1s;
  } */
  
  .arrow:before {
    content: '';
    display: block;
    border: solid #fff;
    border-width: 2px 2px 0 0;
    position: absolute;
    top: 4px;
    width: 10px;
    height: 10px;
  }
  
  .arrow.arrow--left:before {
    transform: rotate(-135deg);
    right: 0;
    transition: right 0.3s 0.2s;
  }
  
  /* .arrow.arrow--left:hover:before {
    right: 100%;
    transition: right 0.3s;
  } */
  
  .arrow.arrow--right {
    text-align: right;
  }
    
  .arrow.arrow--right:before {
    left: 0;
    transform: rotate(45deg);
    transition: left 0.3s 0.2s;
  }
  
  /* .arrow.arrow--right:hover:before {
    left: 100%;
    transition: left 0.3s;
  } */
  
  /* .arrow:hover {
    transform: none;
  } */
}

#input1:checked ~ label #b2,
#input1:checked ~ label #b3,
#input1:checked ~ label #b4 {
  display: none !important;
}
#input2:checked ~ label #b1,
#input2:checked ~ label #b3,
#input2:checked ~ label #b4 {
  display: none !important;
}
#input3:checked ~ label #b1,
#input3:checked ~ label #b2,
#input3:checked ~ label #b4{
  display: none !important;
}
#input4:checked ~ label #b1,
#input4:checked ~ label #b2,
#input4:checked ~ label #b3 {
  display: none !important;
}

@keyframes fadein {
  from {
      opacity: 0;
 }
  to {
      opacity: 1;
 }
}
@keyframes fadeout {
  from {
      opacity: 1;
 }
  to {
      opacity: 0;
 }
}
@keyframes pulse {
  from {
      box-shadow: 0 0 0 0px rgba(39, 36, 82, 1);
 }
  to {
      box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
 }
}
</style>

<script>
export default {
  name: 'HotspotsComponent',
  props: {
    msg: String
  },
  data() {
    return {
      bghover: '',
      windowWidth: window.innerWidth,
      mobileWidth: 768
    }
  },

  mounted: () => {
    if(window.innerWidth < 768){
      let first = document.getElementById("input1")
      first.checked = true
      let bg = document.getElementById("bgcontainer")
      bg.classList.add('bg1hover')
    }
  },

  methods: {
    uncheckAllCehckboxes() {
      const checkboxes = document.getElementsByTagName('input')
      for (var index = 0; index < checkboxes.length; ++index) {
        checkboxes[index].checked = false
        this.bghover = ''
      }
    },
    resetBackground(event) {
      if(this.windowWidth >= this.mobileWidth) {
        if(event.type == "click" && event.target.classList.contains('parent')){
          this.uncheckAllCehckboxes()
        }
        if(event.type == "mouseleave") {
          let checkbox = document.getElementById(event.target.dataset.check)
          if(checkbox.checked == false) {
            this.bghover = ''
          }
        }
      }
    },
    checkToogle(event) {
      if(this.windowWidth >= this.mobileWidth) {
        event.target.checked = true
        if(!event.target.checked){
          this.bghover = ''
        } else {
          this.bghover = event.target.dataset.class
        }
      }
    },
    slide(event) {
      if(event.target.dataset.target != '') {
        let checkbox = document.getElementById(event.target.dataset.target)
        this.uncheckAllCehckboxes()
        checkbox.checked = true
        this.bghover = event.target.dataset.class
      }
    },
  }
}
</script>